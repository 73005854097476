import React, { useCallback, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import AuctionNewOffersCard from '../AuctionCards/AuctionNewOffersCard';
import { CarouselContainer, Dot, SlideContainer, Button } from './styles';
import { ArrowLeft, ArrowRight } from '../Icons';
import { CarList } from '../NewAuctionsList/NewOffersList/styles';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1302 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1302, min: 982 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 982, min: 880 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 880, min: 0 },
    items: 1,
    partialVisibilityGutter: 40,
  },
};

const CustomArrowButton = ({ children, ...props }) => {
  return <Button {...props}>{children}</Button>;
};

const ButtonGroup = ({ goToSlide, carouselState: { currentSlide, totalItems, slidesToShow } }) => {
  const rest = totalItems % slidesToShow;
  const sum = rest === 0 ? totalItems : totalItems + (slidesToShow - rest);
  const buttonsQuantity = sum / slidesToShow;
  const odd = totalItems % 2 !== 0;
  const isDetails = window.location.pathname.includes('detalhes');
  const activedColor = isDetails ? '#D2D4D7' : '#fff';
  const disabledColor = '#D2D4D7';

  const arr = Array(buttonsQuantity)
    .fill(null)
    .map((v, i) => i * slidesToShow);

  arr.pop();
  arr.push(totalItems - slidesToShow);

  const disablePreviousButton = currentSlide === 0;
  const disableNextButton = currentSlide === arr.at(-1) || arr.length <= 1;

  const handlePreviousSlide = () => {
    if (currentSlide - 4 < arr[arr.length - 1]) {
      goToSlide(arr[0]);
    } else goToSlide(currentSlide - 4);
  };

  const handleNextSlide = () => {
    const aux = currentSlide - arr[arr.length - 1];
    if (odd && currentSlide + 4 > arr[arr.length - 1]) goToSlide(currentSlide - aux);
    else if (currentSlide + 4 > arr[arr.length - 1]) goToSlide(arr[arr.length - 1]);
    else goToSlide(currentSlide + 4);
  };

  return (
    <div className="react-multi-carousel-dot-list">
      {arr.map((v, i, thisArr) => (
        <Dot
          key={v}
          isDetails={isDetails}
          active={
            (currentSlide <= v &&
              ((currentSlide === 0 && i === 0) || currentSlide > thisArr[i - 1])) ||
            (v < 0 && i === 0 && currentSlide === 0)
          }
          onClick={() => (v >= 0 ? goToSlide(v) : {})}
        />
      ))}

      <CustomArrowButton
        disabled={disablePreviousButton}
        style={{ left: 0 }}
        onClick={handlePreviousSlide}
        isActive={!disablePreviousButton}
        color={disablePreviousButton ? disabledColor : activedColor}
      >
        <ArrowLeft
          color={disablePreviousButton ? disabledColor : isDetails ? '#2F3741' : activedColor}
        />
      </CustomArrowButton>
      <CustomArrowButton
        disabled={disableNextButton}
        style={{ right: 0 }}
        onClick={handleNextSlide}
        isActive={!disableNextButton}
        color={disableNextButton ? disabledColor : activedColor}
      >
        <ArrowRight
          color={disableNextButton ? disabledColor : isDetails ? '#2F3741' : activedColor}
        />
      </CustomArrowButton>
    </div>
  );
};

const NewCarousel = ({
  auctions,
  isHighlight = false,
  isRecommended = false,
  isRecommendedByDetails = false,
}) => (
  <CarouselContainer>
    <Carousel
      arrows={false}
      containerClass="container"
      draggable
      renderButtonGroupOutside={true}
      renderDotsOutside
      responsive={responsive}
      showDots={false}
      customButtonGroup={<ButtonGroup />}
      partialVisbile
    >
      {auctions?.map((auction, index) => (
        <SlideContainer key={auction?._id}>
          <AuctionNewOffersCard
            key={index}
            auction={auction}
            isHighlight={isHighlight}
            isRecommended={isRecommended}
            isRecommendedByDetails={isRecommendedByDetails}
          />
        </SlideContainer>
      ))}
    </Carousel>
  </CarouselContainer>
);

const NewRecommendedList = ({
  auctions,
  isHighlight = false,
  isRecommended = false,
  isRecommendedByDetails = false,
}) => (
  <CarList>
    {auctions?.map((auction, index) => (
      <AuctionNewOffersCard
        key={index}
        auction={auction}
        isHighlight={isHighlight}
        isRecommended={isRecommended}
        isRecommendedByDetails={isRecommendedByDetails}
      />
    ))}
  </CarList>
);

export { NewCarousel, NewRecommendedList };
