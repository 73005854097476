import React, { useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { rem } from 'polished';

import Modal from '../../../enhanced/Modal';
import { toggleModal } from '../../../../store/actions/ui.actions';
import { Box } from '../../../abstract';

import ConfirmMakeOfferIcon from '../../../../images/icons/confirm-make-offer.svg';

import { toPrice } from '../../../../utils';
import { AUCTION_CONFIRM_AUTOMATIC_OFFER_KEY } from '../../../../constants/modalConstants';

import { createAutoBid } from '../../../../store/autobid/effects';

import {
  Content,
  Icon,
  ActionsButtons,
  Title,
  Description,
  ButtonCancel,
  ConfirmButton,
  confirmMakeOfferStyle,
} from './styles';

import { toAmount } from '../../../../utils/number';
import { useSegment } from '../../../../hooks/useSegment';
import { useDefaultProfile } from '../../../../hooks/useDefaultProfile';
import { makeBid } from '../../../../store/actions/auction.actions';

import { useFlag } from '@unleash/proxy-client-react';

const ConfirmAutomaticOffer = ({ show, toggleModal, others, createAutoBid, makeBid }) => {
  const { user } = useSelector(state => state.authentication);
  const { error } = useSelector(state => state.autoBid);
  const defaultProfile = useDefaultProfile();

  const { trackConfirmBid } = useSegment();

  const enableBidValue = useFlag('change-bid-value-SUI-1054');

  const bidValue = enableBidValue ? 500 : 250;

  const handleCastOffAuction = useCallback(() => {
    toggleModal({ key: AUCTION_CONFIRM_AUTOMATIC_OFFER_KEY, show: false });
    makeBid(
      others?.auctionId,
      toAmount(others?.amountValue),
      user?.dealershipName,
      null,
      defaultProfile?.taxIdentificationNumber,
      false
    );
  }, [toggleModal, others, user?.dealershipName, makeBid]);

  const handleNotCastOffAuction = useCallback(async () => {
    await createAutoBid(others?.auctionId, user.id, toAmount(others?.amountValue), bidValue);
    trackConfirmBid({
      inspection_id: others?.auctionId,
      auction_id: others?.auctionAuId,
      bidAmount: toAmount(others?.amountValue),
      type: 'autoBid',
      section: others?.section,
    });

    if (!error) {
      return toggleModal({ key: AUCTION_CONFIRM_AUTOMATIC_OFFER_KEY, show: false });
    }
  }, [createAutoBid, others, user.id, toggleModal, trackConfirmBid]);

  return (
    <Modal
      toggle={() => {
        toggleModal({ key: AUCTION_CONFIRM_AUTOMATIC_OFFER_KEY, show: false });
      }}
      show={show}
      style={confirmMakeOfferStyle}
    >
      <Box
        style={{
          maxWidth: rem(600),
          padding: `${rem(30)} ${rem(65)} ${rem(25)} ${rem(65)}`,
        }}
        bg="white"
      >
        <Content>
          <Icon src={ConfirmMakeOfferIcon} alt="" />
          <Title>Importante</Title>
          <Description>
            Com este valor você pode arrematar o leilão agora e <br />
            negociar com exclusividade com o vendedor. <br />
            Caso não aceite, seu lance máximo será registrado e <br />
            outros lojistas poderão negociar com exclusividade. <br />
            <strong>Deseja arrematar por R$ {toPrice(others?.amountValue, false)}?</strong>
          </Description>

          <ActionsButtons>
            <ButtonCancel onClick={handleNotCastOffAuction}>Não</ButtonCancel>
            <ConfirmButton onClick={handleCastOffAuction}>Sim</ConfirmButton>
          </ActionsButtons>
        </Content>
      </Box>
    </Modal>
  );
};

const mapStateToProps = state => ({
  show: state.ui.modals.auctionConfirmAutomaticOffer.show,
  others: state.ui.modals.auctionConfirmAutomaticOffer.extras,
});

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  createAutoBid: bindActionCreators(createAutoBid, dispatch),
  makeBid: bindActionCreators(makeBid, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmAutomaticOffer);
