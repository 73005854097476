import React, { useMemo, useState, useCallback, useEffect } from 'react';

import { TiLocation } from 'react-icons/ti';
import { GoVerified } from 'react-icons/go';

import { useHistory } from 'react-router-dom';

import Modal from '../../enhanced/Modal';
import { Dialog } from '../../dialog';

import ClickOutside from 'react-click-outside';

import AutoBidAction from '../../BidActions/AutoBidAction';
import FaHeart from '../../../images/icons/favoritedFillIcon.svg';
import FaRegHeart from '../../../images/icons/favoritedOutlineIcon.svg';
import AlertIcon from '../../../images/icons/automaticOfferAlertIconParticipate.svg';
import { useDefaultProfile } from '../../../hooks/useDefaultProfile';
import EvaluationBadges from '../../EvaluationBadges';

import { RiArrowRightSLine, RiArrowUpSLine } from 'react-icons/ri';

import {
  Container,
  Content,
  VehicleInformationsContainer,
  Row,
  VehicleNameAndModel,
  FavoriteButton,
  VehicleDescription,
  VehicleApprovalAndLocation,
  VehicleApprovalContainer,
  VehicleApprovalLabel,
  VehicleLocationContainer,
  VehicleLocationLabel,
  ShippingPopover,
  VehicleTemperatureContainer,
  VehicleRestrictions,
  Divider,
  VehicleChronometerAndLastOffer,
  VehicleLastOfferLabel,
  VehicleLastOfferAndIsWinningStatus,
  VehicleLastOfferValue,
  VehicleIsWinningContainer,
  VehicleIsWinningLabel,
  VehicleAutoBidContainer,
  AlertIconImg,
  VehicleAutoBidMessageContainer,
  VehicleAutoBidMessageLabel,
  VehicleAutoBidRules,
  VehicleBidContainer,
  ErrorMessage,

  /* */
  BidActionsContainer,
  InputContainer,
  InputLabel,
  InputContent,
  InputStyled,
  QuickKeyboardContainer,
  QuickKeyboardButton,
  VehicleLastOfferResponsive,
  AutoBidViewNinja,
  AutoBidViewNinjaLabel,
  MoreOptionsButton,
  ButtonsContainer,
  BuyForButton,
  Wrapper,
  MoreOptionsWrapper,
  MoreOptionsContent,
} from './styles';

import { useUserFavoriteVehicle } from '../../../context/FavoriteContext';
import { Temperature } from '../../Temperature';
import {
  TimingAuctionContextProvider,
  useTimingAuctionContext,
} from '../../../context/TimingAuctionContext';
import { AuctionItemTimer } from '../../enhanced';
import { useBid } from '../../../hooks/useBid';
import { CarouselPhoto } from '../..';
import { bindActionCreators } from 'redux';
import { toggleModal } from '../../../store/actions/ui.actions';
import { makeBid, setSelectedAuction } from '../../../store/actions/auction.actions';
import {
  AUCTION_BUY_KEY,
  AUCTION_OFFER_KEY,
  AUCTION_CONFIRM_MAKE_OFFER_KEY,
  TIEBREAKER_RULES_KEY,
  MORE_OPTIONS_KEY,
  AUCTION_BUY_FOR,
  CONFIRM_BUY_FOR,
  AUCTION_AUTOMATIC_OFFER_KEY,
  AUCTION_BUY_KEY_CONFIRMATION,
} from '../../Modals';
import { connect, useSelector } from 'react-redux';
import MinusButton from '../../Buttons/MinusButton';
import PlusButton from '../../Buttons/PlusButton';
import { toPrice, toAmount } from '../../../utils/number';
import AppConstants from '../../../constants/appConstants';
import { trackAuctionEvent } from '../../../store/actions/event-tracking.actions';
import { get, find, set } from 'lodash';
import { GoAlert } from 'react-icons/go';
import { useAutoBid } from '../../../hooks/useAutoBid';
import { AUTO_BID_STATUS } from '../../../utils/autobid-enums';
import { useFlag } from '@unleash/proxy-client-react';
import { useSegment } from '../../../hooks/useSegment';
import TemperatureIcon from '../../../images/temperature/button/alta-21px.svg';
import { formatPrice } from '../../../utils/formatPrice';
import { Button } from '../../Buttons';
import { PrimaryButton } from '../../Buttons/old';

const AuctionParticipateCard = ({
  auction,
  toggleModal,
  selectAuction,
  previousCard,
  makeBid,
  authUser,
  trackAuctionEventAction,
  displayWidth,
  makeOfferLoading,
  listAllAutoBidVehicles,
  isFirstChild,
}) => {
  const history = useHistory();

  const { user } = useSelector(state => state.authentication);
  const { bidErrorMessage } = useSelector(state => state.auctions);

  const { currentOffer, currentOfferLabel } = useBid(auction);
  const {
    trackButtonClicked,
    trackViewedDetails,
    trackCarouselInteraction,
    trackConfirmBid,
    trackQuickButtons,
  } = useSegment();

  const {
    currentAutoBidConfigured,
    isActiveAutoBid,
    maximumAutoBidBudget,
    tieWithEdits,
    tieWithoutEdits,
    noEditsAndDesconfigured,
  } = useAutoBid(auction._id);

  const { isFavorite, addFavorite, removeFavorite } = useUserFavoriteVehicle(auction._id);
  const defaultProfile = useDefaultProfile();
  const { milisecondsLeft, secondsLeft: currentAuctionDuration } = useTimingAuctionContext();

  const [showFavoriteModal, setShowFavoriteModal] = useState(false);
  const [showShippingPopover, setShowShippingPopover] = useState(false);

  const [visible, setVisible] = useState(false);

  const enabled = useFlag('auto-bid');
  const enabledScore = useFlag('score');
  const enableBidValue = useFlag('change-bid-value-SUI-1054');

  const minimumBid = enableBidValue ? 500 : AppConstants.MinimumBid;

  const isWinning = useMemo(() => auction?.auction?.winning, [auction?.auction?.winning]);

  const loser = !currentAutoBidConfigured?.isWinner || !isWinning;

  const greaterOrTie =
    !currentAutoBidConfigured?.tie || currentOffer >= currentAutoBidConfigured?.balance;

  const vehicleHasAutoBidActive = useMemo(
    () => find(listAllAutoBidVehicles, { inspectionId: auction?._id, autoBidStatus: 'ON' }),
    [listAllAutoBidVehicles, auction?._id]
  );

  const losingInBothWays = useMemo(
    () => isActiveAutoBid && currentAutoBidConfigured?.updateLimit !== 2 && loser && greaterOrTie,
    [isActiveAutoBid, currentAutoBidConfigured, loser, greaterOrTie]
  );

  const losingInBothWaysWithoutEdits = useMemo(
    () => isActiveAutoBid && currentAutoBidConfigured?.updateLimit === 2 && loser && greaterOrTie,
    [isActiveAutoBid, currentAuctionDuration, loser, greaterOrTie]
  );

  const noEditsInBothWays = useMemo(
    () =>
      isActiveAutoBid &&
      currentAutoBidConfigured?.updateLimit === 2 &&
      !loser &&
      !currentAutoBidConfigured?.tie,
    [isActiveAutoBid, currentAutoBidConfigured, loser]
  );

  const automaticToSingleOfferLosing = useMemo(
    () => !enabled && vehicleHasAutoBidActive?.balance >= currentOffer,
    [enabled, vehicleHasAutoBidActive, currentOffer]
  );

  /* */
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [typedValue, setTypedValue] = useState(currentOffer);
  const [error, setError] = useState('');
  const [showBidAction, setShowBidAction] = useState(false);
  const [withSlider, setWithSlider] = useState(false);
  const [size, setSize] = useState(window.innerWidth);
  const [quickButtons, setQuickButtons] = useState([]);

  const quickKeyboardValues = enableBidValue ? [500, 750] : [250, 500];

  const useQuickKeyboardTemperature = useCallback(() => {
    if (auction?.auction?.winningBid?.amount >= auction?.summary?.vehiclePricing?.hotTarget) {
      return (
        <QuickKeyboardButton onClick={() => handleQuickValue(1000)}>+ 1.000</QuickKeyboardButton>
      );
    }

    return (
      <QuickKeyboardButton
        onClick={() => {
          setTypedValue(auction?.summary?.vehiclePricing?.hotTarget);
          trackQuickButtons({
            inspection_id: auction._id,
            auction_id: auction?.auction?.idAuction,
            type: 'singleBid',
            section: 'participate',
            quickButton: 'Alta',
          });
        }}
      >
        <img src={TemperatureIcon} /> Alta
      </QuickKeyboardButton>
    );
  }, [auction?.auction?.winningBid?.amount, auction?.summary?.vehiclePricing?.hotTarget]);

  const formatToMoney = useCallback(currentValue => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
      .format(currentValue)
      .replace(',00', '');
  }, []);

  const onChangeValue = useCallback(e => {
    setShowSubmitButton(true);

    setTypedValue(Number.parseInt(e.target.value.replace(/\D/g, '')) || 0);
  }, []);

  const handleSubmitOffer = useCallback(() => {
    const bn = auction?.summary?.buynowPrice;
    const bf = auction?.summary?.buyForPrice;

    try {
      const minValueAccepted = currentOffer + minimumBid;

      if (typedValue < minValueAccepted) {
        throw Error(`Faça um lance de ${formatToMoney(minValueAccepted)} ou mais.`);
      }

      if (!bf?.enabled && bn?.enabled && toAmount(bn?.amount) <= toAmount(typedValue)) {
        selectAuction(auction, { remaningTime: currentAuctionDuration * 1000 });

        toggleModal({
          key: AUCTION_CONFIRM_MAKE_OFFER_KEY,
          show: true,
          extras: {
            auction,
            currentOffer: toPrice(typedValue, false),
            section: 'participate',
            submitOffer: () =>
              makeBid(
                auction._id,
                typedValue,
                authUser.dealershipName,
                null,
                false,
                defaultProfile?.taxIdentificationNumber,
                auction?.auction?.isSupperOffer
              ),
          },
        });

        return;
      }

      if (bf?.enabled && toAmount(bf?.amount) <= toAmount(typedValue)) {
        selectAuction(auction, { remaningTime: currentAuctionDuration * 1000 });
        toggleModal({
          key: CONFIRM_BUY_FOR,
          show: true,
          extras: {
            bid: typedValue,
            onSubmit: () =>
              makeBid(
                auction._id,
                typedValue,
                authUser.dealershipName,
                null,
                false,
                defaultProfile?.taxIdentificationNumber,
                auction?.auction?.isSupperOffer
              ),
          },
        });
        return;
      }

      selectAuction(auction, { remaningTime: currentAuctionDuration * 1000 });
      makeBid(
        auction._id,
        typedValue,
        authUser.dealershipName,
        authUser.id,
        null,
        false,
        defaultProfile?.taxIdentificationNumber,
        auction?.auction?.isSupperOffer
      );
      trackConfirmBid({
        inspection_id: auction._id,
        auction_id: auction.auction.idAuction,
        bidAmount: typedValue,
        type: 'singleBid',
        section: 'participate',
        quickButtons,
      });
    } catch (e) {
      setError(e.message);
    }
  }, [
    currentOffer,
    auction,
    quickButtons,
    minimumBid,
    typedValue,
    selectAuction,
    toggleModal,
    makeBid,
  ]);

  useEffect(() => {
    if (!makeOfferLoading) {
      setShowSubmitButton(false);
      setQuickButtons([]);
    }
  }, [makeOfferLoading]);

  useEffect(() => {
    if (currentOffer) setTypedValue(currentOffer);
  }, [currentOffer]);

  /* */
  const vehicleRestrictions = useMemo(
    () => auction?.summary?.documentResearch?.historics?.join(', '),
    [auction?.summary?.documentResearch?.historics]
  );

  const handleAddingFavoriteAuction = useCallback(() => {
    if (isFavorite) return setShowFavoriteModal(true);

    addFavorite();
  }, [isFavorite, addFavorite]);

  const handleRemovingFavoriteAuction = useCallback(() => {
    removeFavorite();
    setShowFavoriteModal(false);
  }, [removeFavorite]);

  const handleClickOutside = useCallback(() => {
    setTypedValue(currentOffer);
    setShowSubmitButton(false);
  }, []);

  const handleTiebreakerRulesModal = useCallback(() => {
    toggleModal({
      key: TIEBREAKER_RULES_KEY,
      show: true,
    });
  }, [toggleModal]);

  const handleSingleOfferModal = useCallback(() => {
    selectAuction(auction, { remainingTime: milisecondsLeft });
    toggleModal({
      key: AUCTION_OFFER_KEY,
      show: true,
      extras: { section: 'participate' },
    });
    trackAuctionEventAction(gtmEvents.CTA_MAKE_OFFER_LISTING, auction._id);
  }, [auction, toggleModal, trackAuctionEventAction, milisecondsLeft]);

  const handleNegotiateExclusivityModal = useCallback(() => {
    selectAuction(auction, { remainingTime: milisecondsLeft });
    toggleModal({ key: AUCTION_BUY_KEY, show: true, extras: { section: 'participate' } });
    trackButtonClicked(auction._id, 'exclusivityBid', auction.auction.idAuction, 'participate');
  }, [selectAuction, auction, milisecondsLeft, toggleModal]);

  const handleBuyForModal = useCallback(() => {
    selectAuction(auction, { remainingTime: milisecondsLeft });
    toggleModal({ key: AUCTION_BUY_FOR, show: true, extras: { section: 'participate' } });
    trackButtonClicked(auction._id, 'buyForBid', auction.auction.idAuction, 'participate');
  }, [selectAuction, auction, milisecondsLeft, toggleModal]);

  const handleAutomaticOfferModal = useCallback(() => {
    selectAuction(auction, { remainingTime: milisecondsLeft });
    toggleModal({
      key: AUCTION_AUTOMATIC_OFFER_KEY,
      show: true,
      extras: { selectedAuction: auction },
    });
    trackButtonClicked(auction._id, 'autoBid', auction.auction.idAuction, 'participate');
  }, [auction, toggleModal, milisecondsLeft, trackButtonClicked]);

  const handleMoreOptionsModal = useCallback(() => {
    selectAuction(auction, { remainingTime: milisecondsLeft });

    toggleModal({ key: MORE_OPTIONS_KEY, show: true, extras: { auction, section: 'participate' } });
  }, [selectAuction, auction, milisecondsLeft, toggleModal]);

  const hasMoreOptions = useMemo(() => {
    const hasLimit =
      !AUTO_BID_STATUS[currentAutoBidConfigured?.autoBidStatus] &&
      currentAutoBidConfigured?.updateLimit !== 2;
    const hasAutoBid = Boolean(!currentAutoBidConfigured) || hasLimit;
    const notHasAutoBid = tieWithoutEdits || losingInBothWaysWithoutEdits;

    if (!enabled || notHasAutoBid) return false;

    return hasAutoBid ? true : false;
  }, [
    enabled,
    currentAutoBidConfigured,
    AUTO_BID_STATUS,
    tieWithoutEdits,
    losingInBothWaysWithoutEdits,
  ]);

  const handleMinusValue = () =>
    setTypedValue(oldState => {
      if (oldState === currentOffer) {
        return currentOffer;
      }

      const subtractionNumber = enableBidValue ? 500 : 250;
      const newState = oldState - subtractionNumber;

      if (newState >= currentOffer) {
        return newState;
      }

      return currentOffer;
    });

  const handlePlusValue = () => {
    setShowSubmitButton(true);
    setTypedValue(oldState => {
      const additionNumber = enableBidValue ? 500 : 250;
      return oldState + additionNumber;
    });
  };

  const handleQuickValue = value => {
    setShowSubmitButton(true);
    setTypedValue(oldState => oldState + value);
    setQuickButtons(oldState => [...oldState, value]);
    trackQuickButtons({
      inspection_id: auction._id,
      auction_id: auction?.auction?.idAuction,
      type: 'singleBid',
      section: 'participate',
      quickButton: value,
    });
  };

  const handleConfirmOffer = () => {
    handleSubmitOffer();
    setError('');
  };

  const useNinjaLayout = useCallback(() => {
    const hasExclusivity = auction?.summary?.buynowPrice?.enabled;
    const hasBuyFor = auction?.summary?.buyForPrice?.enabled;

    if (!authUser.isNinja()) return null;

    return (
      <Wrapper isNinja={authUser.isNinja()}>
        <Button hover="#4ab971" onClick={handleSingleOfferModal}>
          Fazer oferta avulsa
        </Button>

        {!hasBuyFor && hasExclusivity ? (
          <Button
            fontWeight={400}
            bg="#ff4c00"
            hover="#ff7941"
            onClick={handleNegotiateExclusivityModal}
          >
            Negocie a partir de{' '}
            <strong>R$ {formatPrice(auction?.summary?.buynowPrice?.amount)}</strong>
          </Button>
        ) : null}

        {hasBuyFor ? (
          <BuyForButton onClick={handleBuyForModal}>
            Compra garantida por{' '}
            <strong>R$ {formatPrice(auction?.summary?.buyForPrice?.amount)}</strong>
          </BuyForButton>
        ) : null}
      </Wrapper>
    );
  }, [auction, authUser, vehicleHasAutoBidActive]);

  const useStorekeeperLayout = useCallback(() => {
    const hasExclusivity = auction?.summary?.buynowPrice?.enabled;
    const hasBuyFor = auction?.summary?.buyForPrice?.enabled;
    const others = hasBuyFor || hasExclusivity || hasMoreOptions;

    if (authUser.isNinja()) return null;

    const style =
      size > 1024
        ? hasBuyFor || hasExclusivity || hasMoreOptions
          ? { width: '200px' }
          : { width: '320px' }
        : { width: '100%' };

    if (showBidAction) {
      return (
        <ClickOutside onClickOutside={handleClickOutside} style={{ height: '100%' }}>
          <BidActionsContainer>
            <InputContainer>
              <InputLabel>Lance avulso</InputLabel>
              <MinusButton onClick={handleMinusValue} />

              <InputContent>
                <InputStyled onChange={onChangeValue} value={formatToMoney(typedValue)} required />
              </InputContent>

              <PlusButton onClick={handlePlusValue} showBid={enableBidValue ? 500 : ''} />
            </InputContainer>

            {!!error && <ErrorMessage>{error}</ErrorMessage>}

            {withSlider ? (
              <Temperature.WithSlider.Participate auction={auction} currentValue={typedValue} />
            ) : null}

            <QuickKeyboardContainer submitButtonIsVisible={showSubmitButton}>
              {quickKeyboardValues.map(quickValue => (
                <QuickKeyboardButton key={quickValue} onClick={() => handleQuickValue(quickValue)}>
                  + {quickValue}
                </QuickKeyboardButton>
              ))}
              {useQuickKeyboardTemperature()}
            </QuickKeyboardContainer>

            <ButtonsContainer>
              <PrimaryButton
                singleOffer
                onClick={handleConfirmOffer}
                isLoading={makeOfferLoading}
                participate
                style={style}
              >
                Fazer oferta avulsa
              </PrimaryButton>

              {size > 1024 && others ? (
                <MoreOptionsButton onClick={handleMoreOptionsModal}>
                  Mais opções <RiArrowRightSLine size={18} fill="#2f3741" />
                </MoreOptionsButton>
              ) : null}

              {size <= 1024 && hasMoreOptions && (
                <MoreOptionsWrapper>
                  <MoreOptionsButton onClick={() => setVisible(!visible)}>
                    Mais opções de oferta
                    {visible ? (
                      <RiArrowUpSLine size={18} fill="#2f3741" />
                    ) : (
                      <RiArrowRightSLine size={18} fill="#2f3741" />
                    )}
                  </MoreOptionsButton>

                  {visible && (
                    <MoreOptionsContent>
                      <Button hover="#4ab971" borderRadius={8} onClick={handleAutomaticOfferModal}>
                        Oferta Automática
                      </Button>

                      {hasBuyFor && (
                        <BuyForButton onClick={handleBuyForModal}>
                          Compra garantida por{' '}
                          <strong>R$ {formatPrice(auction?.summary?.buyForPrice?.amount)}</strong>
                        </BuyForButton>
                      )}

                      {!hasBuyFor && hasExclusivity && (
                        <Button
                          fontWeight={400}
                          bg="#ff4c00"
                          hover="#ff7941"
                          onClick={handleNegotiateExclusivityModal}
                        >
                          Negocie a partir de{' '}
                          <strong>R$ {formatPrice(auction?.summary?.buynowPrice?.amount)}</strong>
                        </Button>
                      )}
                    </MoreOptionsContent>
                  )}
                </MoreOptionsWrapper>
              )}

              {size <= 1024 && !hasMoreOptions && hasBuyFor && (
                <BuyForButton onClick={handleBuyForModal}>
                  Compra garantida por{' '}
                  <strong>R$ {formatPrice(auction?.summary?.buyForPrice?.amount)}</strong>
                </BuyForButton>
              )}

              {size <= 1024 && !hasMoreOptions && !hasBuyFor && hasExclusivity && (
                <Button
                  fontWeight={400}
                  bg="#ff4c00"
                  hover="#ff7941"
                  onClick={handleNegotiateExclusivityModal}
                >
                  Negocie a partir de{' '}
                  <strong>R$ {formatPrice(auction?.summary?.buynowPrice?.amount)}</strong>
                </Button>
              )}
            </ButtonsContainer>
          </BidActionsContainer>
        </ClickOutside>
      );
    }

    if (!isActiveAutoBid || losingInBothWaysWithoutEdits) {
      return (
        <Wrapper>
          <Button hover="#4ab971" onClick={() => setShowBidAction(true)}>
            Fazer oferta avulsa
          </Button>

          {size > 1024 && others ? (
            <MoreOptionsButton onClick={handleMoreOptionsModal}>
              Mais opções <RiArrowRightSLine size={18} fill="#2f3741" />
            </MoreOptionsButton>
          ) : null}

          {size <= 1024 && hasMoreOptions && (
            <MoreOptionsWrapper>
              <MoreOptionsButton onClick={() => setVisible(!visible)}>
                Mais opções de oferta
                {visible ? (
                  <RiArrowUpSLine size={18} fill="#2f3741" />
                ) : (
                  <RiArrowRightSLine size={18} fill="#2f3741" />
                )}
              </MoreOptionsButton>

              {visible && (
                <MoreOptionsContent>
                  <Button hover="#4ab971" borderRadius={8} onClick={handleAutomaticOfferModal}>
                    Oferta automática
                  </Button>

                  {hasBuyFor && (
                    <BuyForButton onClick={handleBuyForModal}>
                      Compra garantida por{' '}
                      <strong>R$ {formatPrice(auction?.summary?.buyForPrice?.amount)}</strong>
                    </BuyForButton>
                  )}

                  {!hasBuyFor && hasExclusivity && (
                    <Button
                      fontWeight={400}
                      bg="#ff4c00"
                      hover="#ff7941"
                      onClick={handleNegotiateExclusivityModal}
                    >
                      Negocie a partir de{' '}
                      <strong>R$ {formatPrice(auction?.summary?.buynowPrice?.amount)}</strong>
                    </Button>
                  )}
                </MoreOptionsContent>
              )}
            </MoreOptionsWrapper>
          )}

          {size <= 1024 && !hasMoreOptions && hasBuyFor && (
            <BuyForButton onClick={handleBuyForModal}>
              Compra garantida por{' '}
              <strong>R$ {formatPrice(auction?.summary?.buyForPrice?.amount)}</strong>
            </BuyForButton>
          )}

          {size <= 1024 && !hasMoreOptions && !hasBuyFor && hasExclusivity && (
            <Button
              fontWeight={400}
              bg="#ff4c00"
              hover="#ff7941"
              onClick={handleNegotiateExclusivityModal}
            >
              Negocie a partir de{' '}
              <strong>R$ {formatPrice(auction?.summary?.buynowPrice?.amount)}</strong>
            </Button>
          )}
        </Wrapper>
      );
    }
  }, [
    authUser,
    showBidAction,
    typedValue,
    error,
    withSlider,
    auction,
    showSubmitButton,
    makeOfferLoading,
    size,
    hasMoreOptions,
    losingInBothWaysWithoutEdits,
    isActiveAutoBid,
    visible,
  ]);

  const useAutoBidLayout = useCallback(() => {
    // if (user.isNinja() && vehicleHasAutoBidActive && enabled) {
    //   return (
    //     <AutoBidViewNinja>
    //       <AlertIconImg
    //         src={AlertIcon}
    //         alt="Círculo preenchido de cinza com um ponto de exclamação no centro."
    //       />
    //       <AutoBidViewNinjaLabel>
    //         Não é possível dar lances pois o leilão está com a{' '}
    //         <strong>oferta automática ativa.</strong>
    //       </AutoBidViewNinjaLabel>
    //     </AutoBidViewNinja>
    //   );
    // }
    if (
      isActiveAutoBid &&
      !tieWithoutEdits &&
      !losingInBothWaysWithoutEdits &&
      enabled &&
      !user.isNinja()
    ) {
      return <AutoBidAction auction={auction} isFirstChild={isFirstChild} />;
    }
  }, [
    user,
    vehicleHasAutoBidActive,
    enabled,
    isActiveAutoBid,
    tieWithEdits,
    losingInBothWaysWithoutEdits,
    auction,
    isFirstChild,
  ]);

  const scoreLayout = () => {
    if (!enabledScore) return null;

    if (size > 414) {
      return (
        <React.Fragment>
          <GoVerified fill="#22aa52" style={{ marginRight: '0.2rem' }} />
          <VehicleApprovalLabel
            onClick={() => {
              trackViewedDetails(auction?._id, auction?.auction?.idAuction, 'participate');
              toggleModal({
                key: AUCTION_BUY_KEY_CONFIRMATION,
                show: false,
              });
              toggleModal({
                key: AUCTION_BUY_FOR,
                show: false,
              });
              toggleModal({
                key: AUCTION_OFFER_KEY,
                show: false,
              });
              toggleModal({
                key: AUCTION_AUTOMATIC_OFFER_KEY,
                show: false,
              });
            }}
            to={`/detalhes/${auction._id}`}
          >
            {Math.round(auction?.summary?.goodPointsCount || 0)}% dos pontos aprovados.
          </VehicleApprovalLabel>
        </React.Fragment>
      );
    } else {
      return (
        <VehicleApprovalLabel href={`/detalhes/${auction._id}`}>
          <strong>{Math.round(auction?.summary?.goodPointsCount || 0)}%</strong> dos pontos
          aprovados.
        </VehicleApprovalLabel>
      );
    }
  };

  useEffect(() => {
    if (!!bidErrorMessage) {
      setError(bidErrorMessage);
    }
  }, [bidErrorMessage]);

  useEffect(() => {
    if (previousCard) {
      previousCard(auction?._id);
    }
  }, [previousCard, auction?._id]);

  useEffect(() => {
    if (typedValue > currentOffer) setWithSlider(true);
    else setWithSlider(false);
  }, [typedValue, currentOffer]);

  useEffect(() => {
    if (isActiveAutoBid && losingInBothWaysWithoutEdits) setShowBidAction(true);
    else if (!isActiveAutoBid && !isWinning) setShowBidAction(true);
    else setShowBidAction(false);
  }, [isActiveAutoBid, isWinning, losingInBothWaysWithoutEdits]);

  window.addEventListener('resize', () => setSize(window.innerWidth));

  return (
    <TimingAuctionContextProvider auction={auction?.auction}>
      <Container id={auction?._id}>
        <Content>
          <CarouselPhoto
            photos={auction?.summary?.photos.filter(url => !url.includes('vimeo'))}
            arrows
            dots
            counter
            participate
            onClickSlide={() => {
              trackViewedDetails(auction?._id, auction?.auction?.idAuction, 'participate');

              toggleModal({
                key: AUCTION_BUY_KEY_CONFIRMATION,
                show: false,
              });
              toggleModal({
                key: AUCTION_BUY_FOR,
                show: false,
              });
              toggleModal({
                key: AUCTION_OFFER_KEY,
                show: false,
              });
              toggleModal({
                key: AUCTION_AUTOMATIC_OFFER_KEY,
                show: false,
              });
            }}
            href={`/detalhes/${auction._id}`}
            trackCarousel={() =>
              trackCarouselInteraction(auction?._id, auction?.auction?.idAuction, 'participate')
            }
          />

          <VehicleInformationsContainer>
            <Row>
              <VehicleNameAndModel
                onClick={() => {
                  trackViewedDetails(auction?._id, auction?.auction?.idAuction, 'participate');
                  toggleModal({
                    key: AUCTION_BUY_KEY_CONFIRMATION,
                    show: false,
                  });
                  toggleModal({
                    key: AUCTION_BUY_FOR,
                    show: false,
                  });
                  toggleModal({
                    key: AUCTION_OFFER_KEY,
                    show: false,
                  });
                  toggleModal({
                    key: AUCTION_AUTOMATIC_OFFER_KEY,
                    show: false,
                  });
                }}
                to={`/detalhes/${auction._id}`}
              >
                {auction?.summary?.make} {auction?.summary?.model}
              </VehicleNameAndModel>
              <FavoriteButton onClick={handleAddingFavoriteAuction}>
                {isFavorite ? <img src={FaHeart} /> : <img src={FaRegHeart} />}
              </FavoriteButton>
            </Row>

            <VehicleDescription>
              {auction?.summary?.yearLabel} - {auction?.summary?.version}{' '}
              {auction?.summary?.kmLabel}
            </VehicleDescription>

            <VehicleApprovalAndLocation enabledScore={enabledScore}>
              {/* <VehicleApprovalContainer>{scoreLayout()}</VehicleApprovalContainer> */}
              <VehicleLocationContainer
                onClick={() => setShowShippingPopover(!showShippingPopover)}
              >
                <TiLocation
                  size={27}
                  color="#22aa52"
                  style={{ verticalAlign: 'text-top', marginRight: '0.3em' }}
                />
                <VehicleLocationLabel>
                  {auction?.summary?.vehicleYardRegion ?? auction?.summary?.state}
                </VehicleLocationLabel>
                {showShippingPopover && (
                  <ShippingPopover>
                    O carro será disponibilizado no pátio desta localidade.
                  </ShippingPopover>
                )}
              </VehicleLocationContainer>
              <EvaluationBadges auction={auction} />
            </VehicleApprovalAndLocation>

            {user.isNinja() ? (
              <VehicleTemperatureContainer>
                <Temperature.Ninja auction={auction} currentValue={currentOffer} />
              </VehicleTemperatureContainer>
            ) : null}

            {displayWidth === 'lg' && (
              <VehicleRestrictions>
                <strong>Restrições: </strong>
                {vehicleRestrictions ?? 'Nada Consta'}.
              </VehicleRestrictions>
            )}
          </VehicleInformationsContainer>

          <Divider />

          <VehicleChronometerAndLastOffer>
            <AuctionItemTimer participate showBuyNowButton={false} auction={auction} />

            <VehicleLastOfferResponsive displayWidth={displayWidth}>
              <VehicleLastOfferLabel>Lance atual do leilão:</VehicleLastOfferLabel>
              <VehicleLastOfferAndIsWinningStatus>
                <VehicleLastOfferValue>R$ {currentOfferLabel}</VehicleLastOfferValue>
                <VehicleIsWinningContainer bg={isWinning ? '#05762d' : '#c50000'}>
                  <VehicleIsWinningLabel>
                    {isWinning ? 'Ganhando' : 'Perdendo'}
                  </VehicleIsWinningLabel>
                </VehicleIsWinningContainer>
              </VehicleLastOfferAndIsWinningStatus>

              {!authUser.isNinja() &&
              !isActiveAutoBid &&
              auction?.auction?.winning &&
              !withSlider ? (
                <Temperature.Basic auction={auction} currentValue={currentOffer} />
              ) : null}

              {tieWithEdits && enabled ? (
                <VehicleAutoBidMessageContainer>
                  <GoAlert
                    size={40}
                    fill="#C50000"
                    style={{
                      alignSelf: 'flex-start',
                      marginRight: '.313rem',
                      height: '18px',
                    }}
                  />
                  <VehicleAutoBidMessageLabel>
                    <strong>
                      Outro lojista definiu primeiro o limite de {maximumAutoBidBudget}. Insira um
                      limite maior para continuar na disputa.
                    </strong>{' '}
                    <VehicleAutoBidRules onClick={handleTiebreakerRulesModal}>
                      Consulte a regra de desempate.
                    </VehicleAutoBidRules>
                  </VehicleAutoBidMessageLabel>
                </VehicleAutoBidMessageContainer>
              ) : null}

              {tieWithoutEdits && enabled ? (
                <VehicleAutoBidMessageContainer>
                  <GoAlert
                    size={40}
                    fill="#C50000"
                    style={{
                      alignSelf: 'flex-start',
                      marginRight: '.313rem',
                      height: '18px',
                    }}
                  />
                  <VehicleAutoBidMessageLabel>
                    <strong>
                      Outro lojista definiu primeiro o limite de {maximumAutoBidBudget}.
                    </strong>{' '}
                    Edições encerradas, continue na disputa através da oferta avulsa
                    {auction?.summary.buynowPrice.enabled && ' ou negocie com exclusividade'}.
                  </VehicleAutoBidMessageLabel>
                </VehicleAutoBidMessageContainer>
              ) : null}

              {losingInBothWays && !tieWithEdits && enabled ? (
                <VehicleAutoBidMessageContainer>
                  <GoAlert
                    size={40}
                    fill="#C50000"
                    style={{
                      alignSelf: 'flex-start',
                      marginRight: '.313rem',
                      height: '18px',
                    }}
                  />
                  <VehicleAutoBidMessageLabel>
                    <strong>
                      O lance de {maximumAutoBidBudget} foi superado por outro lojista. Insira um
                      valor maior de limite para voltar a disputar o leilão.
                    </strong>
                  </VehicleAutoBidMessageLabel>
                </VehicleAutoBidMessageContainer>
              ) : null}

              {losingInBothWaysWithoutEdits && !tieWithoutEdits && enabled ? (
                <VehicleAutoBidMessageContainer>
                  <GoAlert
                    size={40}
                    fill="#C50000"
                    style={{
                      alignSelf: 'flex-start',
                      marginRight: '.313rem',
                      height: '18px',
                    }}
                  />
                  <VehicleAutoBidMessageLabel>
                    <strong>
                      O lance de {maximumAutoBidBudget} foi superado por outro lojista.
                    </strong>{' '}
                    Edições encerradas, continue na disputa através da oferta avulsa
                    {auction?.summary.buynowPrice.enabled && ' ou negocie com exclusividade'}.
                  </VehicleAutoBidMessageLabel>
                </VehicleAutoBidMessageContainer>
              ) : null}

              {noEditsInBothWays && enabled ? (
                <VehicleAutoBidContainer>
                  <VehicleAutoBidMessageContainer>
                    <AlertIconImg
                      src={AlertIcon}
                      alt="Círculo preenchido de cinza com um ponto de exclamação no centro."
                    />
                    <VehicleAutoBidMessageLabel>
                      Você não pode mais alterar o <strong>lance máximo</strong> porque atingiu o
                      número máximo de edições.
                    </VehicleAutoBidMessageLabel>
                  </VehicleAutoBidMessageContainer>
                </VehicleAutoBidContainer>
              ) : null}

              {noEditsAndDesconfigured && enabled && !withSlider ? (
                <VehicleAutoBidContainer>
                  <VehicleAutoBidMessageContainer>
                    <AlertIconImg
                      src={AlertIcon}
                      alt="Círculo preenchido de cinza com um ponto de exclamação no centro."
                    />
                    <VehicleAutoBidMessageLabel>
                      Você não pode mais fazer a <strong>oferta automática</strong> porque atingiu o
                      número máximo de edições.
                    </VehicleAutoBidMessageLabel>
                  </VehicleAutoBidMessageContainer>
                </VehicleAutoBidContainer>
              ) : null}

              {automaticToSingleOfferLosing ? (
                <VehicleAutoBidContainer>
                  <VehicleAutoBidMessageContainer>
                    <GoAlert
                      size={110}
                      fill="#C50000"
                      style={{
                        alignSelf: 'flex-start',
                        marginRight: '.313rem',
                        height: '18px',
                      }}
                    />
                    <VehicleAutoBidMessageLabel>
                      <strong>
                        O seu lance foi superado rapidamente porque outro lojista definiu um valor
                        de oferta previamente que cobriu o seu lance de modo automático.
                      </strong>{' '}
                      Essa é uma nova funcionalidade, ainda está em teste e por isso não está
                      habilitada para você. Caso também queira utilizar esta funcionalidade, entre
                      em contato com o atendimento.
                    </VehicleAutoBidMessageLabel>
                  </VehicleAutoBidMessageContainer>
                </VehicleAutoBidContainer>
              ) : null}
            </VehicleLastOfferResponsive>
          </VehicleChronometerAndLastOffer>
        </Content>

        <VehicleBidContainer>
          {useAutoBidLayout()}
          {useNinjaLayout()}
          {useStorekeeperLayout()}
        </VehicleBidContainer>

        {size <= 1024 && (
          <VehicleRestrictions>
            <strong>Restrições: </strong>
            {vehicleRestrictions ?? 'Nada Consta'}.
          </VehicleRestrictions>
        )}

        <Modal show={showFavoriteModal}>
          <Dialog
            header="Deseja remover esse veículo dos seus favoritos?"
            onCTAClick={handleRemovingFavoriteAuction}
            onSecondaryBtnClick={() => setShowFavoriteModal(false)}
            primaryBtnLabel="Sim, remover"
            primaryBtnVariant="warning"
            secondaryBtnLabel="Não remover"
            secondaryBtnVariant="ghost.warning"
          />
        </Modal>
      </Container>
    </TimingAuctionContextProvider>
  );
};

const mapStateToProps = state => ({
  bidSuccess: state.auctions.bidSuccess,
  authUser: state.authentication.user,
  makeOfferLoading: state.ui.makeOfferLoading,
  listAllAutoBidVehicles: state.listAllAutoBidVehicles.payload,
});

const mapDispatchToProps = dispatch => ({
  selectAuction: bindActionCreators(setSelectedAuction, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  makeBid: bindActionCreators(makeBid, dispatch),
  trackAuctionEventAction: trackAuctionEvent,
});

export default connect(mapStateToProps, mapDispatchToProps)(AuctionParticipateCard);
