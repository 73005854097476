import React from 'react';

export const RecommendedIcon = ({ color = '#82878e', ...props }) => (
  <svg
    width={17}
    height={23}
    viewBox="0 0 20 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 3.25A3.25 3.25 0 013.25 0h13a3.25 3.25 0 013.25 3.25v17.875a1.625 1.625 0 01-1.625 1.625H1.625a1.625 1.625 0 001.625 1.625h15.438a.812.812 0 110 1.625H3.25A3.25 3.25 0 010 22.75V3.25zm10.488 2.096a.813.813 0 00-1.474 0L7.678 8.223l-3.15.38a.813.813 0 00-.454 1.402l2.324 2.158-.613 3.112a.813.813 0 001.193.866l2.772-1.54 2.77 1.54a.812.812 0 001.193-.866l-.609-3.112 2.324-2.158a.813.813 0 00-.457-1.402l-3.15-.382-1.333-2.876v.001zM8.96 9.316l.79-1.7.79 1.7a.812.812 0 00.638.465l1.861.224-1.373 1.276a.81.81 0 00-.244.752l.36 1.838-1.637-.91a.812.812 0 00-.79 0l-1.638.91.36-1.838a.812.812 0 00-.243-.752l-1.371-1.276 1.857-.223a.812.812 0 00.639-.464l.001-.002z"
      fill={color}
    />
  </svg>
);
