import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { find, unionBy } from 'lodash';

import { AUTO_BID_STATUS } from '../utils/autobid-enums';

export function useAutoBid(inspectionId) {
  const bidsByUser = useSelector(state => state.listAutoBidVehicles?.payload);

  const allBids = useSelector(state => state.listAllAutoBidVehicles?.payload);

  const { user } = useSelector(state => state.authentication);

  const listingAutoBids = unionBy(bidsByUser, user.isNinja() ? allBids : [], 'autoBidId');

  const currentAutoBidConfigured = useMemo(() => find(listingAutoBids, { inspectionId }), [
    listingAutoBids,
    inspectionId,
  ]);

  const isActiveAutoBid = useMemo(
    () => !!currentAutoBidConfigured && AUTO_BID_STATUS[currentAutoBidConfigured?.autoBidStatus],
    [currentAutoBidConfigured, currentAutoBidConfigured?.autoBidStatus]
  );

  const maximumAutoBidBudget = useMemo(
    () =>
      new Intl.NumberFormat('pt-br', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(Math.ceil(currentAutoBidConfigured?.balance)),
    [currentAutoBidConfigured?.balance]
  );

  const currentUpdateLimit = useMemo(() => {
    if (currentAutoBidConfigured?.updateLimit === 0) {
      return `02 edições restantes`;
    }

    return currentAutoBidConfigured?.updateLimit === 1
      ? '01 edição restante'
      : 'edições encerradas';
  }, [currentAutoBidConfigured?.updateLimit]);

  const tieWithEdits = useMemo(
    () =>
      isActiveAutoBid &&
      !currentAutoBidConfigured?.isWinner &&
      currentAutoBidConfigured?.tie &&
      currentAutoBidConfigured?.updateLimit !== 2,
    [
      isActiveAutoBid,
      currentAutoBidConfigured?.isWinner,
      currentAutoBidConfigured?.tie,
      currentAutoBidConfigured?.updateLimit,
    ]
  );

  const tieWithoutEdits = useMemo(
    () =>
      isActiveAutoBid &&
      !currentAutoBidConfigured?.isWinner &&
      currentAutoBidConfigured?.tie &&
      currentAutoBidConfigured?.updateLimit === 2,
    [
      isActiveAutoBid,
      currentAutoBidConfigured?.isWinner,
      currentAutoBidConfigured?.tie,
      currentAutoBidConfigured?.updateLimit,
    ]
  );

  const losingWithEdits = useMemo(
    () =>
      isActiveAutoBid &&
      !currentAutoBidConfigured?.isWinner &&
      !currentAutoBidConfigured?.tie &&
      currentAutoBidConfigured?.updateLimit !== 2,
    [
      isActiveAutoBid,
      currentAutoBidConfigured?.isWinner,
      currentAutoBidConfigured?.tie,
      currentAutoBidConfigured?.updateLimit,
    ]
  );

  const losingWithoutEdits = useMemo(
    () =>
      isActiveAutoBid &&
      !currentAutoBidConfigured?.isWinner &&
      !currentAutoBidConfigured?.tie &&
      currentAutoBidConfigured?.updateLimit === 2,
    [
      isActiveAutoBid,
      currentAutoBidConfigured?.isWinner,
      currentAutoBidConfigured?.tie,
      currentAutoBidConfigured?.updateLimit,
    ]
  );

  const noEdits = useMemo(
    () =>
      isActiveAutoBid &&
      currentAutoBidConfigured?.isWinner &&
      !currentAutoBidConfigured?.tie &&
      currentAutoBidConfigured?.updateLimit === 2,
    [
      isActiveAutoBid,
      currentAutoBidConfigured?.isWinner,
      currentAutoBidConfigured?.tie,
      currentAutoBidConfigured?.updateLimit,
    ]
  );

  const noEditsAndDesconfigured = useMemo(
    () => !isActiveAutoBid && currentAutoBidConfigured?.updateLimit === 2,
    [isActiveAutoBid, currentAutoBidConfigured?.updateLimit]
  );

  return {
    currentAutoBidConfigured,
    isActiveAutoBid,
    maximumAutoBidBudget,
    currentUpdateLimit,
    tieWithEdits,
    tieWithoutEdits,
    losingWithEdits,
    losingWithoutEdits,
    noEdits,
    noEditsAndDesconfigured,
  };
}
