import React, { useMemo, memo, useCallback } from 'react';

import { Container, ErrorMessage, QuickKeyboardContainer, QuickKeyboardButton } from './styles';

import { PrimaryButton } from '../../Buttons/old';
import { Button } from '../../Buttons';
import DealersDropdown from '../../Selects/DealersDropdown';
import TaxProfilesDropdown from '../../Selects/TaxProfilesDropdown';
import BidInput from '../../Inputs/BidInput';

import { toAmount } from '../../../utils/number';
import { Temperature } from '../../Temperature';

import TemperatureIcon from '../../../images/temperature/button/alta-21px.svg';
import { useSegment } from '../../../hooks/useSegment';

import { useFlag } from '@unleash/proxy-client-react';

const SingleOfferAction = ({
  auction,
  inputWidth,
  justifyContent,
  showQuickKeyboard = true,
  showExclusivityButton = true,
  inputValue,
  handleAmountIncrementOrDecrement,
  onChange,
  onBlur,
  onSubmit,
  handleQuickKeyboard,
  handleNegotiateExclusivity,
  selectedDealer,
  onDealerChange,
  relatedDealers,
  showDealersDropdown,
  selectedTaxProfile,
  onTaxProfileChange,
  taxProfiles,
  isLoading,
  error,
  showSlider,
  isNinja,
  updateAmount,
  setQuickButtons,
  isRecommended,
  setSection,
}) => {
  const enabledQuickKeyboard = useFlag('change-bid-value-SUI-1054');

  const quickKeyboardValues = [250, 500];

  const { trackQuickButtons } = useSegment(isRecommended);
  const handleQuickButtons = value => {
    setQuickButtons(oldState => [...oldState, value]);
    trackQuickButtons({
      inspection_id: auction._id,
      auction_id: auction?.auction?.idAuction,
      type: 'singleBid',
      section: setSection,
      quickButton: value,
    });
  };

  const useQuickKeyboardTemperature = useCallback(() => {
    if (auction?.auction?.winningBid?.amount >= auction?.summary?.vehiclePricing?.hotTarget) {
      return (
        <QuickKeyboardButton
          onClick={() => {
            handleQuickKeyboard(1000);
            handleQuickButtons(1000);
          }}
        >
          + 1.000
        </QuickKeyboardButton>
      );
    }
    return (
      <QuickKeyboardButton
        onClick={() => {
          updateAmount(auction?.summary?.vehiclePricing?.hotTarget);
          handleQuickButtons('Alta');
        }}
      >
        <img src={TemperatureIcon} /> Alta
      </QuickKeyboardButton>
    );
  }, [auction?.auction?.winningBid?.amount, auction?.summary?.vehiclePricing?.hotTarget]);

  const useSliderLayout = useCallback(() => {
    if (isNinja || !showSlider) return null;

    return <Temperature.WithSlider.Default auction={auction} currentValue={toAmount(inputValue)} />;
  }, [showSlider, auction, inputValue, isNinja]);

  return (
    <Container>
      <BidInput
        inputWidth={inputWidth}
        handleAmountIncrementOrDecrement={handleAmountIncrementOrDecrement}
        handleMinusButton={() => handleAmountIncrementOrDecrement('dec')}
        handlePlusButton={() => handleAmountIncrementOrDecrement('inc')}
        value={inputValue}
        onChange={onChange}
        onBlur={onBlur}
        label="Lance avulso"
        showBid={enabledQuickKeyboard ? 500 : ''}
      />

      {!!error && <ErrorMessage>{error}</ErrorMessage>}

      {showQuickKeyboard && !showDealersDropdown && !enabledQuickKeyboard && (
        <QuickKeyboardContainer justifyContent={justifyContent}>
          {quickKeyboardValues.map(quickValue => (
            <QuickKeyboardButton
              key={quickValue}
              onClick={() => {
                handleQuickKeyboard(quickValue);
                handleQuickButtons(quickValue);
              }}
            >
              + {quickValue}
            </QuickKeyboardButton>
          ))}
          {useQuickKeyboardTemperature()}
        </QuickKeyboardContainer>
      )}

      {showDealersDropdown && (
        <DealersDropdown
          selectedDealer={selectedDealer}
          onDealerChange={onDealerChange}
          relatedDealers={relatedDealers}
        />
      )}

      {selectedDealer && taxProfiles.length > 0 && (
        <TaxProfilesDropdown
          selectedTaxProfile={selectedTaxProfile}
          onTaxProfileChange={onTaxProfileChange}
          taxProfiles={taxProfiles}
        />
      )}

      {useSliderLayout()}

      <PrimaryButton
        singleOffer
        isLoading={isLoading}
        modal
        onClick={() => onSubmit()}
        disabled={toAmount(inputValue) <= auction?.auction?.winningBid?.amount}
        automaticOffer
      >
        Fazer oferta avulsa
      </PrimaryButton>

      {auction?.summary?.buynowPrice?.enabled && showExclusivityButton && (
        <Button
          fontWeight={400}
          bg="#ff4c00"
          hover="#ff7941"
          onClick={() => handleNegotiateExclusivity()}
        >
          Negocie a partir de{' '}
          <strong>R$ {formatPrice(auction?.summary?.buynowPrice?.amount)}</strong>
        </Button>
      )}
    </Container>
  );
};

export default memo(SingleOfferAction);
