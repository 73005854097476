import React from 'react';
import { MdAdd } from 'react-icons/md';
import { Button } from './styles';

const PlusButton = ({ showBid, ...rest }) => (
  <Button {...rest}>
    <MdAdd size={20} fill="#fff" />
    {showBid}
  </Button>
);

export default PlusButton;
