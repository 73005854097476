import styled from 'styled-components';

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0.3rem 0.7rem;

  background-color: #2274a5;

  border: none;
  border-radius: 0.5rem;
  box-shadow: 0 0.375rem 1.25rem rgba(26, 132, 63, 0.3);

  cursor: pointer;
  outline: none;
  color: white;
  margin: 0rem 0.5rem;
`;
